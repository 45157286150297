import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '../layouts/index';
import axios from 'axios';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';

const OrderDetails = () => {
  const [customerOrders, setCustomerOrders] = useState([]);
  const [user, setUser] = useState(null);
  const [locale, setLocale] = useState('es');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      const storedUser = localStorage.getItem('user');
      setLocale(storedLocale);
      setUser(storedUser ? JSON.parse(storedUser) : null);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      if (user) {
        try {
          const environment = window.location.href.includes('https://www.osdocasal.com/') ? 'Live' : 'Test';
          const url = `https://api.osdocasal.com:8090/ordersByMail?email=${encodeURIComponent(user.email)}&environment=${environment}`;
          const response = await axios.get(url);
          if (response.data && Array.isArray(response.data)) {
            setCustomerOrders(response.data);
          }
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      }
    };
  
    if (user) {
      fetchOrders();
    }
  }, [user]);  

  const data = useStaticQuery(graphql`
    query {
      site: datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          siteName
        }
      }
    }
  `);

  const translations = locale === 'es' ? translationsEs : translationsEn;
  const totalPages = Math.ceil(customerOrders.length / ordersPerPage);

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = customerOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const calculateTotalOrderPrice = (items) => {
    return items.reduce((total, item) => total + item.totalPrice, 0);
  };

  const handleImageError = (event) => {
    event.target.src = "https://www.datocms-assets.com/103480/1713793116-logo.webp";
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToPrevPage = () => {
    setCurrentPage(currentPage => Math.max(1, currentPage - 1));
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage => Math.min(totalPages, currentPage + 1));
  };

  return (
    <Layout
      site={data.site}
      seo={{ ...data.site.globalSeo, ...data.site.faviconMetaTags }}
      lang={locale}
      title={"Mis pedidos - OS DO CASAL"}
      description={"Mis pedidos"}
      noIndex={true}
    >
      <div className="Order-history">
        <h2 className="Order-history__name">{translations['my-orders']}</h2>
        {currentOrders.length > 0 ? (
          currentOrders.map((order, index) => (
            <div className="orders" key={index}>
              <h3>{translations['order-placed-by']} {order.billingAddress.fullName}</h3>
              {order.items.map((item, itemIndex) => (
                <div className="item" key={itemIndex}>
                  <Link to={item.url}>
                    <img 
                      className="item__image"
                      src={item.image} 
                      alt={item.name} 
                      onError={handleImageError}
                    />
                  </Link>
                  <div>
                    <p><strong>{translations['order-product']}</strong> {item.name}</p>
                    <p><strong>{translations['order-quantity']}</strong> {item.quantity}</p>
                    <p><strong>{translations['order-total-product']}</strong> {item.totalPrice} €</p>
                  </div>
                </div>
              ))}
              <h4>{translations['order-total-amount']} {calculateTotalOrderPrice(order.items).toFixed(2)} €</h4>
            </div>
          ))
        ) : (
          <div className="no-orders">
            <p>No tienes pedidos.</p>
          </div>
        )}
        <div className="pagination">
          <button 
            className={`prev-page-button ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={goToPrevPage} 
            disabled={currentPage === 1}>
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`page-number ${currentPage === i + 1 ? 'current' : ''}`}
            >
              {i + 1}
            </button>
          ))}
          <button 
            className={`next-page-button ${currentPage === totalPages ? 'disabled' : ''}`}
            onClick={goToNextPage} 
            disabled={currentPage === totalPages}>
            Siguiente
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default OrderDetails;